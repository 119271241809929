<template>
  <div class="container">
    <b-col>
      <b-row>
        <b-col cols="12" sm="6">
          <h4 class="mt-4 mb-0">{{firma.firma_unvan}}</h4>
          <div class="breadcrumb">
                <ul class="list-inline mb-0">
                    <li class="list-inline-item"><a href="">Anasayfa</a> - </li>
                    <li class="list-inline-item"><a href="">Firmalar</a> - </li>
                    <li class="list-inline-item"><a href="">Firma Detay</a></li>
                </ul>
            </div>
        </b-col>
        <b-col class="text-right mt-4" cols="12" sm="6">
          <date-picker 
              v-model="range" 
              type="date" 
              format="YYYY-MM-DD" 
              value-type="YYYY-MM-DD"
              placeholder="Tarih Seçin"
              range-separator=" / "
              confirm 
              range 
              class="datePicker"
              ref="datePicker"
              confirm-text='Tamam'></date-picker>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col cols="6" md="2" v-for="atik_tur in firma_miktar_tur_group_box" :key="atik_tur.atik_id" class="mini_box">
          <div class="mini_box_bg">
            <div class="atik_ad_mini_box">{{atik_tur.atik_ad}}</div>
            {{atik_tur.miktar ? atik_tur.miktar : 0}} Kg
          </div>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col cols="12" md="6">
          <area-chart
            :data="c_data" 
            :colors="['#007bff', '#666']" 
            label="kg"
            lang="tr"
            class="mt-5 mb-5" />
        </b-col>
        <b-col cols="12" md="6">
          <column-chart
            :data="firma_miktar_tur_group" 
            :colors="['#007bff', '#666']" 
            lang="tr"
            class="mt-5 mb-5" />
        </b-col>
      </b-row>
    </b-col>

    <b-container>
      <b-row>
        <router-link :to="'/firmaForm/' + $route.params.firma_id" class="btn btn-primary btn-block mb-5">Firmayı Düzenle</router-link>
      </b-row>
    </b-container>

    <b-col>
      <b-row>
        <b-table v-if="atik_alimlari" responsive striped hover :items="atik_alimlari"></b-table>
        <div v-else>
          <p>Sistemde kayıtlı veri bulunamadı!</p>
        </div>
      </b-row>
    </b-col>
  </div>
</template>

<script>
export default {
  data(){
    return{
      rangeChange: null,
    }
  },

  mounted(){
    var veri = {
      firma_id: this.$route.params.firma_id,
      start: this.days.start,
      finish: this.days.finish,
    }
    console.log(veri)
    this.$store.dispatch("firma_miktar_chart", veri);
    this.$store.dispatch("firma_miktar_tur_group", veri);
    this.$store.dispatch("firmaBul", veri);
  },

  computed : {
    c_data(){
      if(this.$store.state.firma_miktar_chart){
        return this.$store.state.firma_miktar_chart.reduce(function(map, obj) {
            map[obj.gun] = obj.miktar * 1;
            return map;
        }, {});
      }else{
        return [];
      }
    },
    firma_miktar_tur_group(){
      return this.$store.state.firma_miktar_tur_group;
    },
    firma_miktar_tur_group_box(){
      return this.$store.state.firma_miktar_tur_group_box;
    },
    atik_alimlari(){
      if(this.$store.state.atik_alimlari){
        return this.$store.state.atik_alimlari.filter(r=>r.atik_alim_firma == this.$route.params.firma_id).map(c => {
          return {
            atik_alim_tarih : c.atik_alim_tarih,
            atik_alim_user: c.user_adsoyad,
            atik_alim_tur: c.atik_ad,
            atik_alim_miktar : c.atik_alim_miktar,
          }
        });
      }else{
        return [];
      }
    },
    firma(){
      return this.$store.state.firmalar.filter(c => c.firma_id == this.$route.params.firma_id)[0];
      //return this.$store.state.firma;
    },
    days(){
      return this.$store.state.days;
    },
    range: {
      get(){
          return this.rangeChange === null ? [this.days.start, this.days.finish] : this.rangeChange;
      },
      set(val){
          this.rangeChange = val        	
      }
    }
  },

  watch: {
    rangeChange(a){
      var deger = {
          firma_id: this.$route.params.firma_id,
          start: a[0],
          finish: a[1],
      }
      this.veriGuncelle(deger);
    }
  }
}
</script>


<style scoped>
.button{
  color: #333;
  padding: 0;
}
.mini_box {
    margin-top: 30px;
    position: relative;
}
.mini_box_bg {
    background-color: #f1f1f1;
    padding: 15px;
}
.atik_ad_mini_box {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #047afb;
}
.breadcrumb {
    background: transparent;
    padding: 0;
    font-size: 14px;
}
.breadcrumb a{
    color: #333;
}
@media (max-width: 767px){
  .datePicker{
    width: 100%;
  }
}
</style>